<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form
      @submit.stop.prevent="handleSubmit(onSubmit)"
      @reset.prevent="closeLeavesEditDetailsForm"
    >
      <b-row class="mb-2">
        <!-- number of days -->
        <b-col md="4" cols="12">
          <validation-provider
            #default="validationContext"
            name="total_remain"
            :rules="required"
          >
            <b-form-group
              label="Nbr. Jours restant:"
              label-for="total_remain"
              :state="getValidationState(validationContext)"
            >
              <b-input-group append="Jour(s)">
                <b-form-input
                  id="total_remain"
                  v-model="remainDays"
                  type="number"
                  placeholder="Nombre de jour(s)..."
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- From -->
        <b-col md="6" cols="12">
          <validation-provider
            #default="validationContext"
            name="last_leave_date"
            :rules="required"
          >
            <b-form-group
              label="Dernière Date de congé :"
              label-for="last_leave_date"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                id="last_leave_date"
                v-model="lastLeaveDateFormatted"
                :config="flatConfig"
                class="form-control"
                placeholder="Dernière Date..."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="invalid || isLoading"
          >
            <b-spinner v-if="isLoading" small></b-spinner>
            Mettre à jour
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
          >
            Annuler
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";

import moment from "moment";
moment.locale("fr");

import router from "@/router";
import store from "@/store";
import { computed, ref, watch } from "@vue/composition-api";

export default {
  name: "EmployeeSalaryStatementEditDetails",
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    leaveDetails: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const toast = useToast();
    const flatConfig = ref({
      wrap: true,
      altFormat: "d-m-Y",
      dateFormat: "d-m-Y",
      maxDate: moment().format("DD-MM-YYYY"),
      locale: French, // locale for this instance only
    });

    const details = ref(JSON.parse(JSON.stringify(props.leaveDetails)));
    const remainDays = ref(0);
    const lastLeaveDate = ref(null);

    watch(
      details,
      (val) => {
        remainDays.value = val.total_remain;
        lastLeaveDate.value = val.last_leave_from_date;
      },
      { deep: true, immediate: true }
    );

    const lastLeaveDateFormatted = computed({
      get() {
        if(lastLeaveDate.value === null) return;
        return moment(lastLeaveDate.value, "YYYY-MM-DD").format("DD-MM-YYYY");
      },
      set(val) {
        lastLeaveDate.value = moment(val, "DD-MM-YYYY").format("YYYY-MM-DD");
      },
    });
    const resetDetails = () =>
      (details.value = JSON.parse(JSON.stringify(props.leaveDetails)));

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetDetails);

    const closeLeavesEditDetailsForm = () => {
      emit("leavesDetailsUpdated");
      resetForm();
    };

    const onSubmit = async () => {
      const isValid = refFormObserver.value.validate();
      if (!isValid) return;

      isLoading.value = true;

      const payload = {
        uid: router.currentRoute.params.id,
        details: {
          last_date: lastLeaveDateFormatted.value,
          remain_days: remainDays.value,
        },
      };

      try {
        const response = await store.dispatch(
          "employee/updateLeaveDetails",
          payload
        );

        if (!response.success) {
          handleErrors(response.errors);
          showToast(
            "Problème de la mise à jour du congé.",
            "AlertCircleIcon",
            "danger"
          );
          return;
        }
        handleSuccess();
      } catch (error) {
        showToast(
          "Une erreur est survenue lors de la mise à jour du congé.",
          "AlertCircleIcon",
          "danger"
        );
      } finally {
        isLoading.value = false;
      }
    };

    const handleErrors = (errors) => {
      for (const [field, message] of Object.entries(errors)) {
        refFormObserver.value.setErrors({ [field]: message[0] });
      }
    };

    const handleSuccess = () => {
      closeLeavesEditDetailsForm();
      showToast("Congé mise à jour avec succès.", "CheckIcon", "success");
    };

    const showToast = (message, icon, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: icon,
          variant: variant,
        },
      });
    };

    return {
      remainDays,
      lastLeaveDateFormatted,
      onSubmit,
      resetDetails,
      refFormObserver,
      getValidationState,
      closeLeavesEditDetailsForm,

      isLoading,
      flatConfig,
      required,
    };
  },
};
</script>
